import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"

import { Link } from "gatsby-theme-material-ui"
import Seo from "../components/seo"

export const Head: HeadFC = () => <title>Home Page</title>

// const EmptyLayout = ({ children }) => <> {children} </>

const IndexPage = ({ pageContext } : PageProps) => (
  <> 
  {/* <DashDocsLayout pageContext={pageContext} CanvasComponent={EmptyLayout}> */}
    <Seo title='Home' />
    <h1>Documentation des ontologies disponibles dans l'explorateur</h1>
    {/* <p>Ce site de publication est pour l'heure très minimaliste, mais qui a vocation à évoluer avec vos contributions.</p> */}
    <p><Link to='/hrrdf/CatalogType/'>Naviguer entre les classes</Link></p>
    <p><Link to='/hrrdf/assessedCompetencies/'>Naviguer entre les propriétés</Link></p>
    <p><Link to='/downloadPage/'>Télécharger le modèle RDFS</Link></p>
  {/* </DashDocsLayout> */}
  </>
)

export default IndexPage


